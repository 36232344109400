import { DeliveryType, PaidDeliveryType, StoreDeliveryDto, SupportedCountryCodes } from 'types';
import { formatCurrency, getCurrencyText } from '../../core/utils/utils';
import i18n from 'i18next';

export const getDeliveryTextMobile = (deliveries: StoreDeliveryDto[]) => {
    const storeDelivery = deliveries.find(
        el => el.type === DeliveryType.STORE_DELIVERY || el.type === DeliveryType.DELIVERY_SERVICE,
    );
    const pickUp = deliveries.find(el => el.type === DeliveryType.PICKUP);
    const specificDelivery = deliveries.find(el => el.type === DeliveryType.SPECIFIC_DELIVERY);

    if (!storeDelivery?.enabled && !pickUp?.enabled && !specificDelivery?.enabled) {
        return '';
    }
    if (storeDelivery?.enabled && pickUp?.enabled) {
        return i18n.t('common:deliveryInfo.pickupAndShipping');
    }
    if (specificDelivery?.enabled && pickUp?.enabled) {
        return i18n.t('common:deliveryInfo.specialDeliveryAndPickup');
    }
    if (pickUp?.enabled && !storeDelivery?.enabled && !specificDelivery?.enabled) {
        return i18n.t('common:deliveryInfo.pickup');
    }
    if (storeDelivery?.enabled) {
        const prices: number[] = storeDelivery.data.map(el => el.price).sort();
        const pricingModelText =
            getCurrencyText() + storeDelivery.data.some(el => el.pricingModel === PaidDeliveryType.PER_KM)
                ? i18n.t('common:deliveryInfo.distance')
                : '';
        let deliveryText = i18n.t('common:deliveryInfo.shipping');
        switch (prices.length) {
            case 1:
                deliveryText +=
                    prices[0] === 0 ? ' ' + i18n.t('common:deliveryInfo.free') : ` ${prices[0]}${pricingModelText}`;
                break;
            case 2:
                deliveryText += ` ${prices[0]} — ${prices[1]}${pricingModelText}`;
                break;
            default:
                deliveryText = '';
                break;
        }
        return deliveryText;
    }
    if (specificDelivery?.enabled) {
        return i18n.t('common:deliveryInfo.specialDelivery');
    }
};

export const getDeliveryTextDesktop = (deliveries: StoreDeliveryDto[], isoCode?: SupportedCountryCodes) => {
    const storeDelivery = deliveries.find(
        el => el.type === DeliveryType.STORE_DELIVERY || el.type === DeliveryType.DELIVERY_SERVICE,
    );
    const pickUp = deliveries.find(el => el.type === DeliveryType.PICKUP);
    const specificDelivery = deliveries.find(el => el.type === DeliveryType.SPECIFIC_DELIVERY);
    const result = [];
    if (!storeDelivery?.enabled && !pickUp?.enabled && !specificDelivery?.enabled) {
        return '';
    }
    if (storeDelivery?.enabled) {
        const prices: number[] = storeDelivery.data.map(el => el.price).sort();
        const pricingModelText = storeDelivery.data.some(el => el.pricingModel === PaidDeliveryType.PER_KM)
            ? `${formatCurrency(null, isoCode)}${i18n.t('common:deliveryInfo.distance')}`
            : formatCurrency(null, isoCode);
        let deliveryText = i18n.t('common:deliveryInfo.shipping');
        switch (prices.length) {
            case 1:
                deliveryText +=
                    prices[0] === 0 ? ' ' + i18n.t('common:deliveryInfo.free') : ` ${prices[0]}${pricingModelText}`;
                break;
            case 2:
                deliveryText += ` ${prices[0]} — ${prices[1]}${pricingModelText}`;
                break;
            default:
                deliveryText = '';
                break;
        }
        if (deliveryText) {
            result.push(deliveryText);
        }
    }
    if (pickUp?.enabled) {
        result.push(i18n.t('common:deliveryInfo.pickup'));
    }
    if (specificDelivery?.enabled) {
        result.push(i18n.t('common:deliveryInfo.specialDelivery'));
    }
    return result.join(' •\u00A0');
};
